.cp-work {
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, #30323B 0%, #212328 100%);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 10fr;

    .cp-header-nav {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .cp-header-filters {
        padding: 0 50px;
        margin: 0;
        justify-self: center;
    }

    .cp-filter-content {
        color: #fff;
    }
}