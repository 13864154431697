.nd-footer {
  .nd-f-icons {
    padding: 100px 0;
    width: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 370px) and (max-width: 500px) {
  .nd-footer {
    padding: 0 20px;
    .nd-f-icons {
      padding: 50px 0;
    }
  }
}