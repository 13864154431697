.nd-resume {
    width: 1000px;
    margin: 0 auto;
    a {
        text-decoration: underline;
    }
    p {
        text-align: left;
        font-family: Raleway;
        color: #000000cc;
    }
    h3 {
        text-align: left;
        font-weight: bold;
        font-family: Raleway;
        color: #000000cc;
    }
    div {
        margin: 15px 0;
    }
    .nd-r-nav {
        display: grid;
        position: sticky;
        top: 0;
        background: #ffffff80;
        grid-template-columns: 1fr 1fr;
        padding: 20px 0;
        margin: 0;
        a {
            text-decoration: none;
        }
        p {
            margin: 0;
            padding: 0;
        }
        p:nth-child(1) {
            text-align: left;
        }
        p:nth-child(2) {
            text-align: right;
        }
    }
    .nd-r-header {
        .nd-rh {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .nd-rh-r p {
                text-align: right;
            }
        }
    }
    .nd-r-footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1px solid #eee;
        margin: 50px auto;
        .nd-rf-r {
            text-align: right;
            a {
                margin-left: 15px;
            }
        }
    }
}