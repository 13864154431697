.cp-filter-grid-4-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 50px;
    justify-items: center;
}

.cp-filter-grid-3-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 50px;
    justify-items: center;
}

.cp-filter-grid-2-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 50px;
    justify-items: center;
}

.cp-filter-grid-1-layout {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 50px;
    justify-items: center;
}

.cp-filter-logo-layout {
    width: 400px;
    height: 400px;
    margin: 20px 10px;
    background: #fff;

    p {
        display: none;
    }
}

.cp-filter-logo-layout:hover {
    p {
        display: inline-block;
        color: #979797;
        text-align: center;
        font-family: "Circular Std";
        font-size: 50px;
        font-weight: 900;
        line-height: 40px;
        margin: 0;
        padding-top: 340px;
    }
}

.cp-filter-illustration-layout {
    width: 600px;
    height: 600px;
    margin: 20px 10px;
    background: #fff;

    p {
        display: none;
    }
}

.cp-filter-illustration-layout:hover {
    p {
        display: inline-block;
        color: #979797;
        text-align: center;
        font-family: "Circular Std";
        font-size: 50px;
        font-weight: 900;
        line-height: 40px;
        margin: 0;
        padding-top: 500px;
    }
}

.cp-filter-landingpage-layout {
    width: 100%;
    height: 800px;
    margin: 20px 10px;
    background: #fff;

    p {
        display: none;
    }
}

.cp-filter-landingpage-layout:hover {
    p {
        display: inline-block;
        color: #979797;
        text-align: center;
        font-family: "Circular Std";
        font-size: 50px;
        font-weight: 900;
        line-height: 40px;
        margin: 0;
        padding-top: 700px;
    }
}