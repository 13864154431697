@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: local('Manrope'), local('Manrope-Regular'), url(./components/fonts/regular.otf) format('otf');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 100;
  src: local('Manrope'), local('Manrope-Thin'), url(./components/fonts/thin.otf) format('otf');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  src: local('Circular Std'), url(./components/fonts/CircularStd.otf) format('otf');
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}