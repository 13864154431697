@keyframes wave-animation {
    0% { transform: rotate(  0.0deg) }
    10% { transform: rotate(-10.0deg) }
    20% { transform: rotate( 12.0deg) }
    30% { transform: rotate(-10.0deg) }
    40% { transform: rotate(  9.0deg) }
    50% { transform: rotate(  0.0deg) }
    100% { transform: rotate(  0.0deg) }
}

.nd-header {
    padding: 40px 0 100px 0;
    .nd-h-content {
        width: 1000px;
        margin: 100px auto 0 auto;
        display: grid;
        grid-template-columns: 2fr 1fr;
        .nd-h-c {
            h2 {
                font-family: Raleway;
                font-size: 50px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 60px;
                text-align: left;
                margin: 0;
                span {
                    animation-name: wave-animation;
                    animation-duration: 2.5s;
                    animation-iteration-count: infinite;
                    transform-origin: 70% 70%;
                    display: inline-block;
                }
                u a {
                    color: #000;
                }
            }
            p {
                font-family: Raleway;
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 30px;
                text-align: left;
                margin: 30px 0 50px 0;
            }
            .nd-h-sm {
                h5 {
                    font-family: Raleway;
                    font-size: 22px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 26px;
                    margin: 0;
                    float: left;
                    margin-right: 50px;
                    text-decoration: underline;
                    a {
                        color: #000;
                    }
                }
                h5:nth-child(1) {
                    margin-right: 100px;
                }
                h5:hover {
                    text-decoration: none;
                }
            }
        }
        .nd-h-dp {
            justify-self: right;
            img {
                width: 300px;
                height: 300px;
                border-radius: 100%;
            }
        }
    }
}

@media only screen and (min-width: 370px) and (max-width: 500px) {
    .nd-header {
        padding: 50px 0;
        .nd-h-content {
            width: 400px;
            padding: 0 20px;
            margin: 0;
            margin-top: 70px;
            display: block;
            .nd-h-c {
                h2 {
                    font-size: 30px;
                    line-height: 40px;
                }
                p {
                    font-size: 12px;
                    margin: 20px 0;
                    line-height: 20px;
                }
                .nd-h-sm {
                    h5 {
                        font-size: 12px;
                        line-height: 16px;
                        margin-right: 25px;
                    }
                }
            }
            .nd-h-dp {
                display: none;
            }
        }
    }
  }