.cp-nav {
    padding: 40px 50px;

    ul {
        text-decoration: none;
        list-style-type: none;
        margin: 0;
        padding: 0;
        float: right;

        li {
            height: 34px;
            // width: 68px;
            color: #747D88;
            font-family: Manrope;
            font-size: 24px;
            font-weight: 100;
            line-height: 34px;
            float: left;
            padding-left: 60px;

            a {
                text-decoration: none;
                color: #747D88;
            }

            a:hover {
                text-decoration: none;
                color: #fff;
            }

            img {
                width: 30px;
                // height: 20px;
                float: right;
                padding-top: 10px;
            }
        }
    }
}

.cp-social-nav {
    ul {
        text-decoration: none;
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 6fr 16fr 6fr;
        padding-top: 100px;

        li {
            // width: 110px;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            color: #FFFFFF;
            font-family: Manrope;
            font-size: 24px;
            font-weight: 100;
            line-height: 34px;
            padding: 50px 12px;
            align-self: center;
            // justify-self: right;

            a {
                text-decoration: none;
                color: #FFFFFF;
            }

            a:hover {
                text-decoration: none;
                color: #747D88;
            }
        }
    }
}

.cp-social-navi {
    padding: 40px 50px;

    ul {
        text-decoration: none;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            color: #747D88;
            font-family: Manrope;
            font-size: 24px;
            font-weight: 100;
            line-height: 34px;
            float: left;
            padding-right: 20px;

            a {
                text-decoration: none;
                color: #747D88;
            }

            a:hover {
                text-decoration: none;
                color: #FFFFFF;
            }
        }
    }
}

.cp-bg-text {
    padding-left: 30px;

    p {
        color: #3F414C;
        font-family: "Circular Std";
        font-size: 120px;
        font-weight: 900;
        line-height: 142px;
        margin: 0;
        text-align: left;
    }
}

.cp-proj-list {
    position: absolute;
    margin-top: 50px;
    left: 3vw;

    ul {
        list-style-type: none;
        text-decoration: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            color: #FFFFFF;
            font-family: Manrope;
            font-size: 24px;
            line-height: 34px;
            padding-right: 3vw;
            text-align: left;
        }
    }
}

.cp-proj-status-button {
    // width: 150px;
    border: 1px solid #979797;
    border-radius: 25px;
    color: #979797;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 100;
    line-height: 19px;
    text-align: center;
    padding: 12px 20px;
    margin: 10px 20px 10px 0;
    float: left;
}

.cp-filters-button {
    border: 1px solid #979797;
    border-radius: 25px;
    color: #979797;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 100;
    line-height: 19px;
    text-align: center;
    padding: 12px 20px;
    margin: 10px;
    float: left;
}

.cp-filters-button:hover {
    border: 1px solid #3F414C;
    color: #3F414C;
    background: #979797;
}