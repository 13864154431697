.cp-project {
    // height: 640px;
    display: grid;
    grid-template-columns: 4fr 7fr;
    padding: 80px 50px;
    text-align: left;

    .cp-info {
        h3 {
            font-family: "Circular Std";
            font-size: 64px;
            font-weight: 900;
            line-height: 81px;
            margin: 0;
        }

        p {
            color: #DEDEDE;
            font-family: Manrope;
            font-size: 20px;
            font-weight: 100;
            line-height: 27px;
        }
    }

    .cp-banner {
        img {
            width: 800px;
            float: right;
        }
    }
}