.cp-home {
    height: 1000px;
    width: 100%;
    background: linear-gradient(135deg, #30323B 0%, #212328 100%);
    display: grid;
    grid-template-columns: 3fr 6fr;

    .cp-bg {
        height: 1000px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 8fr 2fr;

        .cp-desc {

            h3 {
                color: #FFFFFF;
                font-family: "Circular Std";
                font-size: 86px;
                font-weight: 900;
                line-height: 109px;
                text-transform: uppercase;
                text-align: center;
                margin: 0;
                position: absolute;
                top: 700px;
                left: 3vw;
            }

            .cp-desi {
                height: 48px;
                width: 630px;
                background-color: #FFFFFF;
                position: absolute;
                left: 3vw;

                p {
                    color: #000000;
                    font-family: Manrope;
                    font-size: 24px;
                    font-weight: 100;
                    line-height: 34px;
                    text-align: center;
                    padding-top: 5px;
                    margin: 0;
                }
            }
        }
    }

    .cp-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 10fr;

        .cp-main-content {
            display: grid;
            grid-template-columns: 12fr 1fr;
        }
    }
}