.nd-menu {
    width: 1000px;
    height: 53px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    h1 {
        font-family: Montez;
        font-size: 40px;
        letter-spacing: 0;
        line-height: 53px;
        text-align: left;
        margin: 0;
        padding: 0;
        a {
            text-decoration: none;
            color: #000;
        }
    }
    ul {
        margin: 0;
        justify-self: end;
        margin-top: 15px;
        li {
            text-decoration: none;
            list-style-type: none;
            float:  left;
            margin: 0;
            margin-left: 50px;
            margin-top: 2px;
            color: #000000;
            font-family: Raleway;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            a {
                color: #000;
            }
            a:hover {
                text-decoration: underline;
            }
        }
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 30px;
        margin-left: 50px;
      }
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: #2196F3;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 30px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
}

// Width > 370px && < 500px || space between 370px and 500px
@media only screen and (min-width: 370px) and (max-width: 500px) {
  .nd-menu {
    width: 400px;
    padding: 0 20px;
    h1 {
      font-size: 30px;
    }
    ul {
      li {
        font-size: 16px;
        margin: 0;
      }
      li:nth-child(2) {
        display: none;
      }
    }
  }
}

.nd-post-ls-card {
    height: 134px;
    width: 1000px;
    border-radius: 5px;
    background-color: #ECF0F1;
    padding: 30px;
    margin-bottom: 50px;
    h4 {
        color: #000000;
        font-family: Raleway;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 35px;
        text-align: left;
        margin: 0;
        padding: 0;
      }
      .nd-pls-tags {
        margin-top: 20px;
        h6 {
            color: #000000;
            font-family: Raleway;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 19px;
            float: left;
            margin: 0;
            padding: 0;
            margin-right: 30px;
            text-decoration: underline;
        }
      }
  }